<!-- 
	This is the cover sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" align="center" class="row-main my-0">

			<!-- Sign Up Form Column -->
			<a-col :span="24" :md="24" :lg="24" class="col-form" style="max-width: 400px;">
				
				<div style="text-align: center">
					<p class="gradient" style="font-size: 3rem;  font-weight: bold; line-height: 1.1;">Junte-se à LIGA!</p>
					<p class="text-muted">Faça parte da comunidade que vai impulsionar seu crescimento profissional. Entre agora!</p>
					<br>
				</div>

				<!-- Sign Up Form -->
				<a-form
					id="components-form-demo-normal-signup"
					:form="form"
					class="signup-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				> 
					<!-- <a-form-item class="mb-10" label="Name" :colon="false">
						<a-input 
						v-decorator="[
						'name',
						{ rules: [{ required: true, message: 'Please input your name!' }] },
						]" placeholder="Name" />
					</a-form-item>
					<a-form-item class="mb-10" label="Email" :colon="false">
						<a-input 
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]" type="password" placeholder="Password" />
					</a-form-item> -->

					<a-form-item>
						<a-button type="secondary" style="background-color: #0d0f15" block class="signup-form-button">
							<img src="images/logos/Google__G__Logo.svg.png" alt="" style="width: 20px; margin-right: 5px;">Continue com Google
						</a-button>
					</a-form-item>
					<a-form-item style="margin-top: -10px;">
						<a-button type="secondary" style="background-color: #0d0f15" block class="signup-form-button">
							<img src="images/logos/logos-facebook.svg" alt="" style="width: 20px; margin-right: 5px; margin-top: -2px;">Continue com Facebook
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign Up Form -->

			<p class="font-semibold text-muted text-center">Já faz parte da Liga? <router-link to="/sign-in" class="font-bold text-dark">Entre</router-link></p>
			</a-col>
			<!-- / Sign Up Form Column -->


		</a-row>
		
	</div>
</template>

<script>

	export default ({
		data() {
			return {
				// Sign up form object.
				form: this.$form.createForm(this, { name: 'signup_cover' }),
			}
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						console.log('Received values of form: ', values) ;
					}
				});
			},
		},
	})

</script>

<style lang="scss">

.gradient {
  background-image: linear-gradient(-45deg, #FFA63D, #FF3D77, #fd4747, #f74d36);
  color: black;
  background-clip: text;
  -webkit-background-clip: text;

  /* Adicionado: */
  -webkit-text-fill-color: transparent;
}
</style>